$(document).on("turbolinks:load", function () {
  const checkboxes = document.querySelectorAll("input[type=checkbox]")

  var $formContainer = $("[data-hidden-enquiries-form]"),
    $interestSelect = $("#enquiry_interested_in"),
    $phoneField = $("[data-hidable-enquiries-phone]"),
    $recommendationBlocks = $("[data-hidden-recomendations-for]"),
    $careerOptions = $("#enquiry-careers"),
    $newBusinessOptions = $("#enquiry-new_business"),
    $businessNameField = $("#enquiry-business-name"),
    $enquirySuccessLn2 = $("#enquiry-success-msg-ln2"),
    $recommendedPages = $(".recommended-pages"),
    $hideWhenSupplier = $("[data-hidden-for-supplier]"),
    $showWhenSupplier = $("[data-visible-for-supplier]"),
    $option,
    value,
    promptHidden = false

  $careerOptions.hide()

  if ($formContainer.length === 0) {
    return
  }

  const onEnquiryTypeSelect = function (target) {
    $option = $(target).children("option:selected")
    value = $option.val()

    if (value) {
      // Show the container.
      $formContainer.show()

      // Hide the prompt option once interacted with.
      if (promptHidden === false) {
        $(target).children("option").first().remove()
        promptHidden = true
      }

      // Hide and show phone field based on enquiry type flag
      if ($option.data("include-phone")) {
        $phoneField.show()
      } else {
        $phoneField.hide()
      }

      // Hide all recommendation blocks then
      // show the applicable one.
      $recommendationBlocks.hide()

      $recommendationBlocks
        .filter(function (i, elm) {
          return $(elm).data("hidden-recomendations-for") === value
        })
        .show()
    }

    // Show recommended pages
    $recommendedPages.hide()
    $(
      ".recommended-pages.recommended-" + value.toLowerCase().replace(" ", "")
    ).show()

    // Set Intro and Success Text

    $("#intro-text").text(
      $option.data("intro-text") || "Have an enquiry? Submit here."
    )
    $("#success-msg-text").text(
      $option.data("success-text") || "Thanks for your enquiry."
    )

    if (value === "Careers") {
      $careerOptions.show()
      if (
        checkboxes !== null ||
        typeof checkboxes !== "undefined" ||
        checkboxes.length > 0
      )
        for (box = 0; box < checkboxes.length; ++box) {
          checkboxes[box].addEventListener(
            "change",
            function (event) {
              var target = event.target
              if (target.checked) {
                target.parentElement.classList.add("checked")
              } else {
                target.parentElement.classList.remove("checked")
              }
            },
            false
          )
        }
    } else {
      $careerOptions.hide()
    }

    if (
      value === "General" ||
      value === "New Business" ||
      value === "Suppliers"
    ) {
      $businessNameField.show()
    } else {
      $businessNameField.hide()
    }

    if (value === "Media") {
      $enquirySuccessLn2.hide()
    } else {
      $enquirySuccessLn2.show()
    }

    // New additional logic for the wall of fields for suppliers
    if (value === "Suppliers") {
      $showWhenSupplier.show()
      $hideWhenSupplier.hide()
    } else {
      $showWhenSupplier.hide()
      $hideWhenSupplier.show()
    }

    if (value === "New Business") {
      $newBusinessOptions.show()

      if (
        checkboxes !== null ||
        typeof checkboxes !== "undefined" ||
        checkboxes.length > 0
      )
        for (box = 0; box < checkboxes.length; ++box) {
          checkboxes[box].addEventListener(
            "change",
            function (event) {
              var target = event.target
              if (target.checked) {
                target.parentElement.classList.add("checked")
              } else {
                target.parentElement.classList.remove("checked")
              }
            },
            false
          )
        }
    } else {
      $newBusinessOptions.hide()
    }
  }
  $interestSelect.on("change", function (e) {
    onEnquiryTypeSelect(e.target)
  })
  onEnquiryTypeSelect($interestSelect)
})
